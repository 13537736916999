import React, { Component, useMemo, useState } from "react";
import { ReactComponent as CheckMark } from "../../assets/Icons/Checkmark.svg";
import { ReactComponent as HourGlass } from "../../assets/Icons/HourGlass.svg";
import { ReactComponent as ArrowRight } from "../../assets/Icons/arrow-narrow-right.svg";
import { ReactComponent as EyeIcon } from "../../assets/Icons/eye1.svg";
import { useMixpanel } from "react-mixpanel-browser";
import { BeatLoader } from "react-spinners";
import { isModifier } from "typescript";

interface FormState {
  email: string;
  website: string;
  description: string;
}

interface LastStepData {
  title: string;
  header: string;
  type: string;
  name: string;
  email: string;
  website: string;
  description: string;
  icon: React.ReactNode;
}

export default function MatchesModal({
  onSubmitDetails,
  loading,
  isMobile,
  matchCount,
}) {
  const mixpanel = useMixpanel();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const websiteRegex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}([/?].*)?$/;

  const [activeStep, setActiveStep] = useState(0);
  const [lastStepData, setLastStepData] = useState<LastStepData>({
    title: "",
    header: "",
    type: "",
    name: "",
    email: "",
    website: "",
    description: "",
    icon: null,
  });
  const [validationErrors, setValidationErrors] = useState<Partial<FormState>>(
    {}
  );

  const handleContinueButton = (e: any) => {
    e.preventDefault();

    setValidationErrors({});

    const errors: Partial<FormState> = {};

    if (activeStep === 0 && !lastStepData.email) {
      errors.email = "Please enter an email.";
    } else if (activeStep === 0 && !emailRegex.test(lastStepData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (activeStep === 1 && !lastStepData.website) {
      errors.website = "Please enter a website URL.";
    } else if (activeStep === 1 && !websiteRegex.test(lastStepData.website)) {
      errors.website = "Please enter a valid website URL.";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    if (activeStep === 0) {
      mixpanel.track(`Question 1 Submitted - Email`, {
        questionTitle: lastStepData.title,
        questionType: lastStepData.name,
        email: lastStepData.email,
      });
    } else if (activeStep === 1) {
      mixpanel.track(`Question 2 Submitted - Website`, {
        questionTitle: lastStepData.title,
        questionType: lastStepData.name,
        website: lastStepData.website,
      });
    }
    setActiveStep(activeStep + 1);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setValidationErrors({});

    // const errors: Partial<FormState> = {};

    console.log({ validationErrors });
    if (activeStep === 2 && lastStepData.description.length < 10) {
      setValidationErrors({
        ...validationErrors,
        description: "Please enter a description at least 10 characters.",
      });
      return;
    }
    mixpanel.track(`Question 3 Submitted - Description`, {
      questionTitle: lastStepData.title,
      questionType: lastStepData.name,
      description: lastStepData.description,
    });
    localStorage.setItem("quizCompletedOnce", "true");
    localStorage.setItem("dntOpenModal", "true");
    onSubmitDetails({
      email: lastStepData.email,
      website: lastStepData.website,
      description: lastStepData.description,
    });
  };

  useMemo(() => {
    if (activeStep === 0) {
      //   mixpanel.track("Question 1 - Start");
      //   mixpanel.track("Question 1 - Start", {
      //     question: "What email address would you like to send quotes to?",
      //     lastStepData: lastStepData.title,
      //     name: lastStepData.title,
      //     email: lastStepData.title,
      //     website: lastStepData.title,
      //     description: lastStepData.title,
      //   });
      setLastStepData({
        ...lastStepData,
        title: "What email address would you like to send quotes to?",
        type: "email",
        name: "email",
        header: `${matchCount} ${
          matchCount === 1 ? "Match" : "Matches"
        } Secured`,
        icon: <CheckMark />,
      });
    } else if (activeStep === 1) {
      //   mixpanel.track("Question 2 - Start", {
      //     question: "What’s your company’s website?",
      //     lastStepData: lastStepData.title,
      //     name: lastStepData.title,
      //     email: lastStepData.title,
      //     website: lastStepData.title,
      //     description: lastStepData.title,
      //   });
      setLastStepData({
        ...lastStepData,
        title: "What’s your company’s website?",
        type: "website",
        name: "website",
        header: "Almost there!",
        icon: <HourGlass />,
      });
    } else if (activeStep === 2) {
      //   mixpanel.track("Question 3 - Start", {
      //     question: "Tell us a bit about what you need help with?",
      //     lastStepData: lastStepData.title,
      //     name: lastStepData.title,
      //     email: lastStepData.title,
      //     website: lastStepData.title,
      //     description: lastStepData.title,
      //   });
      setLastStepData({
        ...lastStepData,
        title: "Tell us a bit about what you need help with?",
        type: "text",
        name: "description",
        header: "Last Step!",
        icon: <HourGlass />,
      });
    }
  }, [activeStep]);

  return (
    <div
      className="max-w-md mx-auto p-6 bg-whiteColor rounded-lg shadow-md"
      style={{ minWidth: isMobile ? "328px" : "480px", minHeight: "300px" }}
    >
      <div className="flex flex-col items-center space-y-4">
        <div>{lastStepData?.icon}</div>
        <h2 className="text-2xl font-semibold text-gray-900">
          {lastStepData?.header}
        </h2>
        <p
          className="text-base text-center font-semibold leading-6 font-inter"
          style={{ color: "#344054" }}
        >
          {lastStepData?.title}
        </p>

        {activeStep === 2 ? (
          <textarea
            className="w-full"
            style={{ outline: "none", padding: "8px!important" }}
            minLength={10}
            name={"description"}
            value={lastStepData?.description}
            onChange={(e) => {
              setLastStepData({
                ...lastStepData,
                [e.target.name]: e.target.value,
              });

              if (validationErrors?.description) {
                if (
                  !lastStepData.description ||
                  lastStepData.description === ""
                ) {
                  setValidationErrors({
                    ...validationErrors,
                    description:
                      "Please enter a description at least 20 characters.",
                  });
                } else {
                  setValidationErrors({ ...validationErrors, description: "" });
                }
              }
            }}
          />
        ) : (
          <input
            className="w-full"
            type={lastStepData.type}
            name={lastStepData?.name}
            onChange={(e) => {
              setLastStepData({
                ...lastStepData,
                [e.target.name]: e.target.value.toLowerCase(),
              });

              if (validationErrors?.email && e.target.name === "email") {
                if (!e.target.value) {
                  setValidationErrors({
                    ...validationErrors,
                    email: "Please enter an email.",
                  });
                } else if (!emailRegex.test(e.target.value.toLowerCase())) {
                  setValidationErrors({
                    ...validationErrors,
                    email: "Please enter a valid email address.",
                  });
                } else {
                  setValidationErrors({ ...validationErrors, email: "" });
                }
              } else if (
                validationErrors?.website &&
                e.target.name === "website"
              ) {
                if (!e.target.value) {
                  setValidationErrors({
                    ...validationErrors,
                    website: "Please enter a website URL.",
                  });
                } else if (!websiteRegex.test(e.target.value.toLowerCase())) {
                  setValidationErrors({
                    ...validationErrors,
                    website: "Please enter a valid website URL.",
                  });
                } else {
                  setValidationErrors({ ...validationErrors, website: "" });
                }
              }
            }}
            value={
              activeStep === 0
                ? lastStepData?.email
                : activeStep === 1
                ? lastStepData.website
                : ""
            }
          />
        )}
        {validationErrors[
          activeStep === 0
            ? "email"
            : activeStep === 1
            ? "website"
            : "description"
        ] && (
          <p className=" text-sm" style={{ color: "#F04438" }}>
            {
              validationErrors[
                activeStep === 0
                  ? "email"
                  : activeStep === 1
                  ? "website"
                  : "description"
              ]
            }
          </p>
        )}
        {loading ? (
          <BeatLoader
            color="#3364F7"
            cssOverride={{}}
            loading
            speedMultiplier={0.5}
          />
        ) : (
          <div className="flex items-center justify-end mt-10">
            {activeStep === 2 ? (
              <button
                className="w-[244px] bg-[#329BFA] px-[16px] py-[10px] rounded-[8px] text-[#FFFFFF] flex items-center justify-center text-[14px] font-montserrat font-semibold"
                type="submit"
                onClick={(e) => handleSubmit(e)}
                style={{
                  cursor: "pointer",
                }}
              >
                Find Perfect Matches
                <div className="pl-1">
                  <EyeIcon />
                </div>
              </button>
            ) : (
              <button
                className="w-[144px] bg-[#329BFA] px-[16px] py-[10px] rounded-[8px] text-[#FFFFFF] flex items-center justify-center text-[14px] font-montserrat font-semibold"
                type="submit"
                onClick={(e) => handleContinueButton(e)}
                style={{
                  cursor: "pointer",
                }}
              >
                Continue
                <div className="pl-1">
                  <ArrowRight />
                </div>
              </button>
            )}
          </div>
        )}
        {activeStep === 2 && (
          <div>
            <span
              className="text-center font-inter text-xs font-normal leading-custom"
              style={{ color: "#98A2B3" }}
            >
              *By submitting this form, you consent to our{" "}
              <span
                style={{ color: "#1667f2", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://docs.google.com/document/d/1ChZYpEscrJ722CeX1WGxTce1T7V3LUZO3URxB13vLKo/edit?pli=1#heading=h.bzyf4yq6caxk",
                    "_blank" // <- This is what makes it open in a new window.
                  );
                }}
              >
                terms of service
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
